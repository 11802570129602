/* Main Container */
.booking-section2 {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Left Section */
  .left-section3 {
    width: 100%; /* Set the section's width to 30% of the container */
    max-width: 300px; /* Optional: limit the maximum width */
    background-color: #f8f9fa;
    padding: 20px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
}


.add-section3 {
  position: relative;
  width: 30%;
  max-width: 300px;
  background-color: rgb(1, 140, 146);
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow: hidden; /* Hide overflow to ensure images do not spill out */
}

.ad-header {
  background-color: rgb(1, 140, 146);
  padding: 15px;
  color: white;
  font-weight: bold;
  position: relative;
  margin-bottom: 30px;
}

.close-btn {
  position: absolute;
  top: 6px;
  right: 2px;
  background: none;
    background-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  background-color: rgb(0, 92, 116);
  padding-left: 4px;
  padding-right: 4px;
}

.ad-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px; /* Fixed height to fit all images */
  position: relative;
}

.ad-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.ad-image.active {
  opacity: 1;
  animation: slideInFromRight 1.5s ease-in-out;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  50% {
    transform: translateX(0); /* Move to its final position */
  }
  100% {
    transform: translateX(0); /* Keep the image in its final position */
  }
}

/* Mobile-specific styles */

 
  .filter-section {
    margin-top: 20px;
  }
  
  .filter-category {
    margin-bottom: 20px;
  }
  
  .filter-heading {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Form elements */
  .form-select,
  .form-control {
    font-size: 16px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .checkbox-group {
    margin-top: 10px;
  }
  
  .form-check {
    margin-bottom: 10px;
  }
  
  .form-check-input {
    margin-right: 10px;
  }
  
  .form-check-label {
    font-size: 14px;
    color: #555;
  }
  
  /* Main Filter Heading */
  .filter-heading-main {
    background-color: #f0f0f0; /* Light grey background */
    padding: 10px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Additional styling for checkboxes */
  .checkbox-group {
    display: flex;
    flex-direction: column; /* Makes the labels appear vertically */
    gap: 12px; /* Adds gap between the checkboxes */
  }
  
  .checkbox-group label {
    font-size: 16px;
    color: #555;
    display: flex;
    align-items: center;
  }
  
  .star {
    color: gold; /* Gold color for the star */
    margin-right: 8px; /* Space between star and rating text */
    font-size: 18px; /* Star size */
  }
  .form-select {
    position: relative;
    z-index: 10;
  }
  .open-down {
    position: relative;
  }
  
  .open-down:focus {
    outline: none;
  }
  .styled-dropdown {
    border: 1px solid #ddd; /* Default border */
    border-radius: 4px;
    padding: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .styled-dropdown:focus {
    border-color: rgb(1, 140, 146); /* Border color on focus */
    outline: none; /* Removes default blue outline */
    box-shadow: 0 0 5px rgb(1, 140, 146); /* Optional glow effect */
  }
  
  
  
  .open-down::-webkit-scrollbar {
    width: 6px; /* Optional: for customizing scrollbar */
  }
  
  .open-down option {
    position: absolute;
    top: 100%; /* Ensure it starts just below the dropdown */
    z-index: 999; /* Ensures it appears above other elements */
  }
    
  .checkbox-group input {
    margin-right: 10px;
  }
 /* Section Styling */
.ms-book-banner {
  background: rgb(1, 140, 146); /* Background color */
  padding: 40px 0;
  text-align: center;
}

/* Logos Grid */
.logos-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;

}

.logo-item {
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 80px;
}

.logo-item:hover {
  transform: scale(1.1);
}

.logo-circle {
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.logo-img {
  max-width: 70%;
}



/* Search Bar */
.search-bar-wrapper {
  margin-top: 10px;
}

.form-control {
  box-shadow: none;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .logos-grid {
    margin-bottom: 10px;
  }

  .form-control {
    width: 80%; /* Adjusts width for smaller screens */
  }

  .ms-secondary-btn {
    width: auto; /* Keeps button size dynamic */
  }
  .add-section3{
    display: none;
  }
}

  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .filter-heading-main {
      font-size: 20px;
    }
    .filter-heading {
      font-size: 16px;
    }
    .form-select,
    .form-control {
      font-size: 14px;
    }
    .checkbox-group label {
      font-size: 14px;
    margin-bottom: 15px;
    }
  }
  
  /* Right Section */
/* Default Styles */
/* General Styling for Responsive Design */
/* Right Section */
/* General Styles for Larger Screens */
.right-section3 {
  flex: 2 1;
    overflow-y: auto;
    max-height: calc(130vh - 240px);
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    margin-left: 10px;
}

/* Doctor Cards Container */
.doctor-card2s-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

}

/* Doctor Card */
.doctor-card2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  align-items: center;
  position: relative; /* To position the map image */
  margin-bottom: 40px;
}

.doctor-card2:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Doctor Card Left */
.doctor-card2-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  border-right: 4px solid #018C92;
}

.doctor-img {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #ddd;
}

/* Doctor Card Right */
.doctor-card2-right {
  flex: 2;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.doctor-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.doctor-heading {
  font-weight: bold;
  color: #555;
}

.doctor-card2-right p {
  margin: 0;
  font-size: 16px;
  color: #666;
}

/* Map Image */
.map-photo-container {
  display: flex;
  flex-direction: column; /* Stack the map photo and location vertically */
  align-items: center; /* Center them horizontally */
  justify-content: center;
}

.map-photo {
  width: 140px; /* Increased width */
  height: 130px; /* Increased height */
  border-radius: 10px;
  border: 2px solid #ddd;
  object-fit: cover;
  cursor: pointer; /* Indicates the image is clickable */
  margin-bottom: 10px; /* Space between map photo and location text */
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.appointment-btn
 {
  border: none;
  border-radius: 5px;
  padding: 10px 80px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease-in-out;
  z-index: 20px;
}

.appointment-btn {
  background-color: #ffa000;
  color: #fff;
}

.appointment-btn:hover {
  background-color: #e58f00;
}

.apply-btn {
  background-color: rgb(1, 140, 146);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 110px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;
  margin-top: 20px;
}

.apply-btn:hover {
  background-color: rgb(1, 98, 101);
}

.location-btn {
  background-color: #007bff;
  color: #fff;
}

.location-btn:hover {
  background-color: #0056b3;
}

/* Rating Stars */
.rating-stars {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}

.star-filled {
  color: #ffc107; /* Gold color for full stars */
  margin-right: 2px;
}

.star-half {
  color: #ffc107; /* Gold color for half stars */
  margin-right: 2px;
}

.rating-value {
  margin-left: 5px;
  font-size: 14px;
  color: #555; /* Optional styling for the numeric rating */
}
.filter-btn {
  display: none; /* Hidden by default */
}

.close-filter-btn {
  display: none; /* Hidden by default */
}
.verified-logo {
  position: absolute;
  top: 20%;
  left: 24%;
  transform: translate(-50%, -50%);
  width: 70px !important;
  height: 67px !important;
  min-width: 80px;
}
.verified-logo2 {
  position: absolute;
  top: 54%;
  left: -8%;
  transform: translate(-50%, -50%);
  width: 60px !important;
  height: 60px !important;
  min-width: 36px;
}
/* Mobile-Only Filter Button */
@media screen and (max-width: 768px) {
  /* Hide the filter by default on mobile */
  .left-section3 {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%); /* Off-screen by default */
    width: 100%; /* Set a width suitable for the filter section */
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    background-color: #f8f9fa; /* Background for the filter panel */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease; /* Smooth sliding effect */
  }
  
  /* Visible state for the filter */
  .left-section3.mobile-visible {
    transform: translateX(0); /* Slide into view */
  }
  
  /* Filter Button inside the left section */

 
  /* Close Button inside the filter panel */
  .close-filter-btn {
    display: block; /* Visible inside the filter panel */
    background-color: #dc3545; /* Red button */
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%; /* Full-width button */
    text-align: center;
    margin-bottom: 20px;
  }
  .verified-logo {
    position: absolute;
    top: 5%;
    left: 21%;
    transform: translate(-50%, -50%);
    height: 58px !important;
    min-width: 7px;
}
    .apply-btn {
      background-color: rgb(1, 140, 146);
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 110px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      transition: background-color 0.3s ease-in-out;
      margin-top: 50px;
    }
    
    .apply-btn:hover {
      background-color: rgb(1, 98, 101);
    }
  }

/* Responsive Styles */
@media (max-width: 1024px) {
  .doctor-card2 {
    flex-direction: column; /* Stack layout for tablets */
    align-items: flex-start;
  }

  .doctor-card2-left {
    border-right: none; /* Remove border on smaller screens */
    padding-right: 0;
    margin-bottom: 15px; /* Add space between sections */
  }

  .doctor-img {
    width: 150px;
    height: 150px; /* Adjust size for smaller screens */
  }
 
  
  .doctor-card2-right {
    padding-left: 0;
  }

  .doctor-name {
    font-size: 18px; /* Adjust font size */
  }

  .doctor-heading {
    font-size: 16px;
  }

  .doctor-card2-right p {
    font-size: 14px;
  }

  .map-photo {
    width: 120px;
    height: 110px; /* Adjust size for tablets */
  }
  .verified-logo {
    position: absolute;
    top: 5%;
    left: 21%;
    transform: translate(-50%, -50%);
    height: 58px !important;
    min-width: 7px;
}
}

@media (max-width: 768px) {
  .doctor-card2 {
    flex-direction: column; /* Stack layout for mobile */
    max-width: 100%; /* Use full width */
    padding: 15px;
  }

  .doctor-img {
    width: 120px;
    height: 120px; /* Smaller size for mobile */
  }

  .map-photo {
    position: absolute;
    top: 16%;
    left: 77%;
    transform: translate(-50%, -50%);
    width: 80px !important;
    height: 80px !important;
    z-index: 10;
    
  }
  .map-photo-container {
    display: flex;
    flex-direction: column; /* Keep stacking vertically */
    align-items: center; /* Center map and location */
    justify-content: center;
  }
 
  .location-container {
    position: absolute;
    top: 38%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 2px !important;
    height: 85px !important;
    z-index: 10;
    font-size: small;
  }
  .location-text{
    position: absolute;
    top: 26%;
    left: 100%;
    transform: translate(-50%, -50%);
    z-index: 10;
  
    
  }
 
  .appointment-btn,
  .apply-btn {
    padding: 10px 15px;
    font-size: 14px;
  }

  .action-buttons {
    flex-direction: column; /* Stack buttons on mobile */
  }
}

@media (max-width: 480px) {
  .doctor-name {
    font-size: 16px; /* Smaller font for very small screens */
  }

  .doctor-heading {
    font-size: 14px;
  }

  .doctor-card2-right p {
    font-size: 12px;
  }
  .verified-logo {
    position: absolute;
    top: 21px;
    left: 131px;
    width: 52px !important;
    height: 49px !important;
    z-index: 10;
}
}


/* Screen Width < 900px */

  
  /* Section Styling */
.ms-book-banner {
  background: rgb(1, 140, 146); /* Background color */
  padding: 40px 0;
 
}

/* Container */
.search-logo-container {
  background: rgba(255, 255, 255, 0.9); /* White background for content */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}

/* Search Bar Styling */
.search-bar-wrapper {
  display: flex;
  gap: 10px;
}

.ms-secondary-btn {
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.ms-secondary-btn:hover {
  background-color: rgb(0, 115, 120);
}
.logo-item-slider {
  text-align: center;
}

/* Hide dots for desktop view */


.logos-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.logo-item {
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100px;
}

.logo-item:hover {
  transform: scale(1.1);
}

.logo-item.active .logo-circle {
  background-color: rgb(1, 140, 146); /* Blue background for active logo */
}

.logo-circle {
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.logo-img {
  max-width: 90%;
}

.logo-name {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 500;
  color: #333;
  white-space: normal; /* Allows the text to wrap */
  overflow: visible; /* Ensures no truncation */
  text-overflow: unset; /* Disables ellipsis */
  word-break: break-word; /* Breaks long words to avoid overflow */
}

/* New addition to improve UI */
.logo-item.active .logo-name {
  color: rgb(1, 140, 146); /* Change text color for active logo */
  font-weight: bold; /* Emphasize the active logo's name */
}

.logo-item:hover .logo-circle {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .logo-item {
    width: 80px;
  }

  .logo-circle {
    width: 60px;
    height: 60px;
  }

  .logo-name {
    font-size: 10px;
  }
}
  
@media (min-width:310px) {
  .ms-book-banner {   
    padding: 70px 0px 1px;
  }
}

@media (min-width:991px) {
  .ms-doctors .ms-left-border {
      border-left: 1px solid #e1e1e1;
      padding-left: 30px;
  }
}
@media (max-width:991px) {
  .ms-no-border {
      border:none !important;
  }
  .ms-doctors .ms-left-border {
      border-top:1px solid #e1e1e1 !important;
      padding:20px;
  }
}
@media (max-width:767px) {
  .ms-doctors .ms-left-border {
      border: none;
      padding-left: 10px;
      padding-top: 15px;
  }
  .ms-doctors .ms-doc-img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
  }
  .ms-doctors .ms-btn {
      width: 100%;
      text-align: center;
      padding: 7px 15px;
  }
  .ms-doctors p {
      font-size: 14px;
  }
}
.custom-modal {
  width: 100%; /* Set your desired width */
  max-width: none; /* Remove max-width constraint */
  height: 100%; /* Set your desired height */
}
/* Main Container */
/* Overlay to blur background when modal is visible */
.modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px); /* Blur effect on the background */
}

/* Modal content styles */
.modal-content2 {
  background: white;
  padding: 4rem;
  padding-bottom: 4rem;
border-radius: 8px;
width: 90%;
max-width: 600px;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
overflow: hidden;
position: relative;
padding-bottom: 10rem;
}

/* Close button */
.modal-close-btn2 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Modal header */
.modal-header2 h5 {
  margin: 0;
  text-align: center;
}

/* Button to consult now */
.modal-consult-btn2 {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
}

/* Image container */
.modal-images2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; /* Fixed height to fit all images */
  position: relative;
}

.modal-image2 {
  width: 28%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin-top: 14px;
}

.modal-image2.active {
  opacity: 1;
  animation: slideInFromRight 1.5s ease-in-out;
}

@keyframes slideInFromRight2 {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 768px) {
  .modal-content2 {
    width: 95%; /* Use almost full width on smaller screens */
    padding: 1.5rem; /* Reduced padding for smaller screens */
  }

  .modal-header2 h5 {
    font-size: 1rem;
  }

  .modal-consult-btn2 {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .modal-images2 {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .modal-image2 {
    width: 40%;
    max-width: 100px;
  }
  .modal-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px); /* Blur effect on the background */
  }
  
  /* Modal content styles */
  .modal-content2 {
    background: white;
    padding: 4rem;
    padding-bottom: 4rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding-bottom: 15rem;
  }
  
  /* Close button */
  .modal-close-btn2 {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Modal header */
  .modal-header2 h5 {
    margin: 0;
    text-align: center;
    margin-bottom: 37px;
  }
  
  /* Button to consult now */
  .modal-consult-btn2 {
    background-color: rgb(1, 140, 146);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 57px;
    margin-left: 40px;
  }
  
  /* Image container */
  
  .modal-images2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px; /* Fixed height to fit all images */
    position: relative;
  }
  
  .modal-image2 {
    min-width:40% !important;
   min-height: 40% !important;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin-top: 4px;
  
  }
  
  .modal-image2.active {
    opacity: 1;
    animation: slideInFromRight 1.5s ease-in-out;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  50% {
    transform: translateX(0); /* Move to its final position */
  }
  100% {
    transform: translateX(0); /* Keep the image in its final position */
  }
}

@media (max-width: 768px) {
  .modal-content2 {
    width: 100%;
    padding: 4rem;
  }
  
  .modal-image2 {
    width: 100px; /* Slightly smaller for mobile */
    height: 200px; /* Slightly smaller for mobile */
    min-width: 40% !important;
    min-height: 40% !important;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin-top: -45px;
    
  }
  
  .modal-close-btn2 {
    font-size: 16px;
  }
}
  /* Style for the icon circle */

/* Circle styling */

/* Only show the icon on mobile screens */
@media (max-width: 768px) {
  .offer-icon-block {
    display: block; /* Show the icon on screens smaller than 768px */
  }
}



/* Container for the fixed icon */
/* Fixed Icon Block */
.offer-icon-block {
  height: 0;
    width: 49px;
    position: fixed;
    right: 24px;
    top: calc(50% + 190px);
    z-index: 1000;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

/* Circle with Icon */
/* Default: Hide on all devices */
.offer-button2 {
  display: none; /* Hidden by default */
}

/* Show only on mobile view */
@media screen and (max-width: 768px) { /* Adjust max-width for your mobile breakpoint */
  .filter-btn,
  .offer-button2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1, 140, 146);
    color: white;
    padding: 6px 8px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 120px;
    max-width: 180px;
    text-align: center;
    gap: 8px; /* Space between icon & text */
  }
  
  /* Hover Effect */
  .filter-btn:hover,
  .offer-button2:hover {
    background-color: rgb(0, 120, 125);
    transform: scale(1.05);
  }
  
  /* Icons inside buttons */
  .filter-btn span,
  .offer-button2 span {
    display: flex;
    align-items: center;
  }
  
  /* Container for buttons */
  .button-container2 {
    display: flex;
    align-items: center;
    gap: 20px; /* Adds space between buttons */
    flex-wrap: wrap; /* Prevents overlapping on smaller screens */
    margin-left: 20px;
    
  }

  /* Keyframes for Continuous Pop Animation */
 
}
@media screen and (max-width: 340px) { /* Adjust max-width for your mobile breakpoint */
  
    .button-container {
      flex-direction: column; /* Stack buttons vertically */
      gap: 8px; /* Slightly reduce spacing */
    }
  
    .filter-btn,
    .offer-button2 {
      font-size: 13px;
      padding: 6px 10px;
      min-width: 90px;
      max-width: 140px;
    }
  
  .location-container {
    position: absolute;
    top: 44%;
    left: 66%;
    transform: translate(-50%, -50%);
    width: 22px !important;
    height: 147px !important;
    z-index: 10;

    
  }
  .location-text{
    position: absolute;
    top: 12%;
    left: 104%;
    transform: translate(-50%, -50%);
    z-index: 10;
    right: 20%;
  
  
    
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    font-size: 1.2rem;
  }
  }
  
.sort-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.sort-dropdown {
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
.sort-container {
  display: none;
}
}

@media (max-width: 768px) {
.no-container {
 margin-left:"-20px";
}

.no-dropdown {
  padding: 5px;
  border-radius: 4px;
}
}
@media (max-width: 1400px) {
.no-container {
  display: none;
 }
}
@media (max-width: 400px) {
  .verifed-line{
    font-size: 10px;
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s ease-in-out forwards
    }
}
.verifed-line{
  font-size: 15px;
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s ease-in-out forwards
  }
  .cross2 {
    position: absolute;
    top: 10px; /* Adjust vertical alignment */
    right: 60px; /* Adjust horizontal alignment */
    font-size: 18px;
    color: #aaa;
    cursor: pointer;
    z-index: 10;
  }
  
  .cross2:hover {
    color: #333; /* Darker color on hover */
  }
  .cross2 .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    top: -35px; /* Adjust positioning */
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    z-index: 20;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .cross2:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  /* Default styles */


  .custom-grey-dropdown {
    z-index: 1050 !important; /* Ensures it is above other elements */
    background-color: #f0f0f0 !important; /* Light grey background */
    border: 1px solid #ccc !important; /* Subtle border for contrast */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow */
    padding: 10px; /* Adds spacing */
  }
  
  .custom-grey-dropdown .react-datepicker__header {
    background-color: #d9d9d9 !important; /* Grey header */
  }
  
  .custom-grey-dropdown .react-datepicker__time-container {
    background-color: #e6e6e6 !important; /* Grey time picker */
  }
  
  .custom-grey-dropdown .react-datepicker__day {
    color: #333 !important; /* Darker text color */
  }
  
  .custom-grey-dropdown .react-datepicker__day--selected,
  .custom-grey-dropdown .react-datepicker__time-list-item--selected {
    background-color: #bfbfbf !important; /* Highlight selected day and time */
    color: #fff !important; /* White text for selected items */
  }
  