/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  /* Page Container */
  .doctoreprofilee-container {
    display: flex;
    flex-direction: column;
  
  }
  
  /* Header Section */
  .doctoreprofilee-header {
    background-color: #01A09E;
    height: 220px;
    position: relative;
  }
  
  /* Doctor Image */
  .doctoreprofilee-container {
    position: relative; /* Allow absolute positioning of child elements */
  }
  
  .doctor-image3 {
    width: 140px; /* Set the desired width of the container */
    height: 170px; /* Set the desired height of the container */
    display: flex; /* Enable flexbox for alignment */
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
    /* overflow: hidden; */
    background-color: #fff; /* Optional: Add a background color */
    border-radius: 10px; /* Rounded corners on all sides */
    margin: 0 auto; /* Center the container horizontally */
    position: relative; /* Enable absolute positioning for child elements */
  }
  
  .doctor-image3 img {
    width: 100%; /* Ensure the image fills the container width */
    height: 85%; /* Ensure the image fills the container height */
    object-fit: cover; /* Fit the image without distortion */
    border-radius: 12px; /* Ensure all corners of the image are rounded */
    display: block; /* Remove any extra spacing from inline images */
  }
  
  .doctor-logo {
    position: absolute;
    top: -33px;
    right: -31px;
    width: 70px !important;
    height: 71px !important;
    z-index: 10;
}
  
  .doctoreprofilee-content {
    display: flex;
    /* Additional styles for content layout */
  }
  
  .discount {
    /* background-color: rgba(255, 255, 255, 0.9);  */
    position: absolute;
  bottom: 57px;
  left: 5px;
  padding: 17px 11px;
  border-radius: 5px;
  display: none;

  }
  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack children vertically */
    height: 100%; /* Optional: Adjust as needed */
    width: 100%; /* Ensure full-width for centering */
    margin-bottom: 40px;
    margin-top: -50px;
  }
  
  /* Left Section */
  .left-section4 {
    width: 30%;
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: sticky;
    top: 0;
  }
  
  .fixed-box {
    width: 77%;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: -1px;
    min-height: 150px; /* Ensure enough height for content */
    height: auto; /* Box expands based on content */
    box-sizing: border-box; /* Prevents padding from increasing the box size */
    display: flex;
    flex-direction: column; /* Ensure all content stacks vertically */
    justify-content: space-between; /* Distributes space between content and button */
  }
  
  .fixed-box p {
    margin: 3px 0; /* Adds space between paragraphs */
  }
  
  .parent-container {
    margin-top: 20px;  /* Space between content and button */
    width: 100%; /* Ensure the button's container takes full width */
  }
  
  .tele__btn {
    background-color: #01A09E;
    color: #fff;
    border: 2px solid #01A09E;
    padding: 12px 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    width: 100%; /* Ensures button takes the full width of its container */
    transition: background-color 0.3s ease-in-out;
  }
  
  .tele__btn:hover {
    background-color: #01A09E; /* Adds hover effect */
  }
  
  .tele__btn a {
    color: white;
    text-decoration: none;
    display: block;
    width: 100%; /* Ensures the link takes up the full width */
  }
  
  
  .langua.card2 {
    width: 90%;
    height: auto; /* Increased height */
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 4px;
  }
  .schedule-slider {
    max-width: 600px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #f3f3f3;
    border-bottom: 1px solid #ddd;
    border-radius: 10px 10px 0 0;
  }
  
  .slider-header h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .slider-btn {
    background: #01A09E;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .slider-btn:hover {
    background: #008582;
  }
  .calendar-box {
    padding: 50px;
  }
  
  .schedule-card {
    margin-bottom: 0px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    background-color: #f9f9f9;
    margin-top: -30px;
  
  }
  
  .title-section {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .time-sections {
    display: flex;
    justify-content: space-between;
  }
  
  .morning-section
  {
    flex: 1;
    margin: 0 10px;
  }
  
  .morning-section h4,
  .evening-section h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .time-slot {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 8px;
  }
  
  .time-slot p {
    font-size: 0.9rem;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .time-slot p svg {
    color: #000204;
    font-size: 1rem;
  }
  
  /* Ratings Container Styles */
/* Container for the ratings and tooltip */
.google-ratings-container {
  text-align: center;
  margin: 10px 0;
  position: relative; /* For positioning the tooltip */
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Tooltip Styles */
.google-ratings-tooltip {
  position: absolute;
  bottom: 120%; /* Adjust distance above the container */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Prevent interference */
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
}

/* Show Tooltip on Hover (Desktop) */
.google-ratings-container:hover .google-ratings-tooltip {
  opacity: 1;
  pointer-events: auto; /* Allow interaction */
}

/* Show Tooltip on Click (Mobile) */
.google-ratings-tooltip.visible {
  opacity: 1;
  pointer-events: auto; /* Allow interaction */
}

/* Star Styles */
.google-rating-star {
  color: #ffd700;
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0 2px;
}

  @media (max-width: 768px) {
    .schedule-slider {
      max-width: 100%;
    }
  
    .slider-header h3 {
      font-size: 1rem;
    }
  }
  
  /* Right Section */
  .right-section4 {
    width: 70%;
    padding: 20px;
    overflow-y: auto;
    height: calc(120vh); /* Full height minus header */
  }
  
  .scrollable-box {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    height: auto; /* Adjust to content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .scrollable-box h3 {
    margin-bottom: 10px;
  }
  .card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the values as needed */
  
  }
  
  .card h1 {
    margin-top: 0;
  }
  .schedule-container {
    display: flex;
    flex-wrap: wrap; /* Wrap the cards to the next line if there's not enough space */
  }
  
  .langua.card {
    flex: 0 0 calc(50% - 10px); /* Each card takes 50% of the container width with some margin */
    margin-right: 10px; /* Add margin between cards */
    margin-bottom: 10px; /* Add margin between rows */
  }
  
  .languages,
  .experience,
  .about,
  .faqs,
  .qualifications,
  .registrations,
  .specializations,
  .services {
    margin-top: 20px;
  }
  .question {
    display: flex;
    align-items: center;
  }
  
  .question button {
    margin-left: auto;
  }
  
  .answer {
    margin-left: 20px;
  }
  /* Responsive Design */

/* Tablet Screens */
@media (max-width: 1024px) {
  .doctoreprofilee-content {
    flex-direction: column;
    align-items: center; /* Center align sections */
  }

  .left-section4,
  .right-section4 {
    width: 100%;
    padding: 15px;
  }

  .left-section4 {
    position: relative;
    top: auto;
  }
  .doctor-image3 img {
    width: 100%; /* Ensure the image fills the container width */
    height: 85%; /* Ensure the image fills the container height */
    object-fit: cover; /* Fit the image without distortion */
    border-radius: 12px; /* Ensure all corners of the image are rounded */
    display: block; /* Remove any extra spacing from inline images */
  }
}

/* Mobile Screens */
@media (max-width: 768px) {
  .doctoreprofilee-header {
    height: 180px; /* Reduce height for smaller screens */
  }

  .doctor-image3 {
    width: 120px;
    height: 130px;
  }

  .left-section4 {
    padding: 10px;
    gap: 15px;
  }

  .fixed-box,
  .langua.card2 {
    width: 100%; /* Use full width for small screens */
    padding: 10px;
  }

  .right-section4 {
    padding: 10px;
    height: auto; /* Adjust to content on smaller screens */
  }

  .scrollable-box {
    margin-bottom: 15px;
    padding: 15px;
  }
  .fixed-box {
    text-align: center; /* Center-align text on mobile */
    font-size: 14px; /* Smaller font size on mobile */
  }

  .fixed-box .doctor-name {
    font-size: 16px; /* Smaller font for doctor name on mobile */
  }

  .fixed-box .doctor-speciality,
  .fixed-box .doctor-experience {
    font-size: 14px; /* Smaller font size for these items on mobile */
  }

  .fixed-box .parent-container {
    text-align: center; /* Center-align the button on mobile */
  }

  .fixed-box .tele__btn {
    font-size: 14px; /* Smaller button text on mobile */
    padding: 8px 12px; /* Adjust padding for mobile */
    margin: 10px auto; /* Center the button */
  }
  .doctor-image3 img {
    width: 100%; /* Ensure the image fills the container width */
    height: 85%; /* Ensure the image fills the container height */
    object-fit: cover; /* Fit the image without distortion */
    border-radius: 12px; /* Ensure all corners of the image are rounded */
    display: block; /* Remove any extra spacing from inline images */
  }
}

/* Extra Small Mobile Screens */
@media (max-width: 480px) {
  .doctoreprofilee-header {
    height: 150px;
  }

  .doctor-image3 {
    width: 100px;
    height: 120px;
  }

  .left-section4 {
    padding: 8px;
    gap: 10px;
  }

  .fixed-box,
  .langua.card2 {
    padding: 6px;
  }

  .right-section4 {
    padding: 8px;
  }

  .scrollable-box {
    padding: 10px;
  }
  .languages {
    margin-top: 0px;
  }
  .fixed-box h5 {
    padding: 40px;/* Further reduce the margin for mobile screens */
  }

  .fixed-box ul {
    margin-top: -60px; /* Ensure no extra margin at the top of the ul */
    padding-left: 0; /* Keep padding consistent */
  }
  .calendar-box {
    padding: 35px;
  }
  .doctor-image3 img {
    width: 100%; /* Ensure the image fills the container width */
    height: 85%; /* Ensure the image fills the container height */
    object-fit: cover; /* Fit the image without distortion */
    border-radius: 12px; /* Ensure all corners of the image are rounded */
    display: block; /* Remove any extra spacing from inline images */
  }
}

/* Media query for screens with a max width of 767px (mobile view) */
@media (max-width: 767px) {
  /* Style for the FAQ heading */
  h5.mb-4.ms-font-weight-600.ms-primary-color {
    margin-left: 0.5rem !important; /* Adjust left margin */
    font-size: 1.2rem !important;  /* Reduce font size for mobile */
    text-align: center !important; /* Center-align the heading */
  }

  /* Style for the unordered list */
  ul {
    padding-left: 0 !important; /* Remove left padding for mobile */
  }

  /* Style for the FAQ items */
  ul li.languages.card {
    font-size: 12px !important; /* Reduce font size for the list items */
    margin-bottom: 10px !important; /* Add some spacing between items */
    text-align: center !important; /* Center-align text */
  }

  /* Style for the question button */
  ul li.languages.card button {
    justify-content: center !important; /* Center-align the button */
    margin-top: -1px !important; /* Adjust spacing above the button */
  }

  /* Style for the answer text */
  ul li.languages.card p {
    font-size: 12px !important; /* Reduce font size for the answer text */
    margin-top: 5px !important; /* Add spacing between question and answer */
  }
  button {
    margin-top: -10px !important; /* Adjust the top margin for the arrow */
  }

  /* Ensure only this button is targeted by being specific */
  .doctor-image3 img {
    width: 100%; /* Ensure the image fills the container width */
    height: 85%; /* Ensure the image fills the container height */
    object-fit: cover; /* Fit the image without distortion */
    border-radius: 12px; /* Ensure all corners of the image are rounded */
    display: block; /* Remove any extra spacing from inline images */
  }
  .doctor-logo {
    position: absolute;
    top: -6px;
    right: -33px;
    width: 52px !important;
    height: 49px !important;
    z-index: 10;

}
}
